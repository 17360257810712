@use "../base";

nav {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: base.$light-background2;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;

  & > img {
    margin-top: 2rem;
    width: 90%;
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-top: 5rem;

    li {
      display: flex;
      position: relative;
      gap: 2rem;
      color: white;
      height: 65px;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      transition: .1s ease;

      a{
          display: flex;
          gap: 2rem;
            width: 100%;
            height: 100%;
            text-decoration: none;
            display: flex;
            align-items: center;
      }

      p {
        font-size: 14px;
        color: base.$gray-text;
        font-weight: 500;
      }

      svg {
        fill: base.$gray-text;
      }
    }
  }
}

.active-link ::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    269.97deg,
    rgba(245, 192, 1, 0.13) 0.01%,
    rgba(245, 192, 1, 0) 99.96%
  );
  transition: 0.1s ease;
}

.active-link ::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 100%;
  background-color: base.$yellow;
  content: "";
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: 0.1s ease;
}
