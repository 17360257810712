@use "../base";

.table-wrapper{
    padding-bottom: 5rem;
    height: max-content;
}

table {
    width: 100%;
    color: white;
    border-collapse: collapse;
    border-radius: 10px;
    border-spacing: 0 50px;
    min-height: 100px !important;
    & :nth-child(even){
        background-color: base.$light-background;
    
    }
    & :nth-child(odd){
        background-color: base.$table-black-background;
    }

}

thead {
    border-radius: 10px;
    padding: 0 2rem;
    align-items: center;
    height: 60px;
    background-color: base.$table-header-background !important ;
}

thead :first-child{
    padding-left: 2rem;
}

tr {
   height: 60px;
   min-width: 100%;
    position: relative;
   height: 60px;
   overflow: visible;
}

tr :first-child{
    padding-left: 2rem;
}

th {
    font-size: 14px;
    font-weight: 500;
    border-spacing: 0;
    text-align: start;
    flex: 1;
    background-color: unset !important;
}

td {
    font-size: 12px;
    text-align: start;
    flex: 1;
    background-color: unset !important;
    position: relative;
    overflow: visible;

    p{
        background-color: transparent !important;
    }
}

.actions{
    position: absolute;
    top:0;
    right: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent !important;
    cursor: pointer;

    &__options{
        top: 10%;
        right: 30%;
        position: absolute;
        border-radius: 10px;
        overflow: scroll;

        div{
            width: 100%;
            height: 40px;
            padding:1.5rem; 
            background-color: base.$yellow;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 14px;
            transition: .1s ease;
            &:hover{
                background-color: #ffd768;
            }
        }
    }

    img{
    background-color: transparent !important;

    }
}
