@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$background-black: #24292e;
$light-background2: #2f363d;
$input-background: #1c2126;
$table-black-background: #20262C;
$light-background: #272E35;
$table-header-background: #2D3640;
$gray-text: #7F8FA4;
$yellow: #ffbb00;
$green: #13b47a;
$red: #f33636;
$font-family: "Poppins"; //  mixins
$font-family-alt: "Montserrat"; //  mixins

@mixin Flex($direction, $justifyContent, $alignItems, $gap) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $direction;
  gap: $gap;
}
//  end mixins

.bottom-shadow {
  box-shadow: 0px -1px 27px -15px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 0px -1px 27px -15px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0px -1px 27px -15px rgba(0, 0, 0, 0.65);
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.modal-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  padding: 0.5rem 2rem;
  z-index: 99999999;
}

.mb15 {
  margin-bottom: 15rem !important;
}
.mb12 {
  margin-bottom: 12rem !important;
}
.mb11 {
  margin-bottom: 11rem !important;
}
.mb10 {
  margin-bottom: 10rem !important;
}
.mb9 {
  margin-bottom: 9rem !important;
}
.mb8 {
  margin-bottom: 8rem !important;
}
.mb7 {
  margin-bottom: 7rem !important;
}
.mb6 {
  margin-bottom: 6rem !important;
}
.mb5 {
  margin-bottom: 5rem !important;
}
.mb4 {
  margin-bottom: 4rem !important;
}
.mb3 {
  margin-bottom: 3rem !important;
}
.mb2 {
  margin-bottom: 2rem !important;
}
.mb1 {
  margin-bottom: 1rem !important;
}
.mb0 {
  margin-bottom: 0rem !important;
}

.mt15 {
  margin-top: 15rem !important;
}
.mt12 {
  margin-top: 12rem !important;
}
.mt11 {
  margin-top: 11rem !important;
}
.mt10 {
  margin-top: 10rem !important;
}
.mt9 {
  margin-top: 9rem !important;
}
.mt8 {
  margin-top: 8rem !important;
}
.mt7 {
  margin-top: 7rem !important;
}
.mt6 {
  margin-top: 6rem !important;
}
.mt5 {
  margin-top: 5rem !important;
}
.mt4 {
  margin-top: 4rem !important;
}
.mt3 {
  margin-top: 3rem !important;
}
.mt2 {
  margin-top: 2rem !important;
}
.mt1 {
  margin-top: 1rem !important;
}
.mt0 {
  margin-top: 0rem !important;
}

.ml15 {
  margin-left: 15rem !important;
}
.ml12 {
  margin-left: 12rem !important;
}
.ml11 {
  margin-left: 11rem !important;
}
.ml10 {
  margin-left: 10rem !important;
}
.ml9 {
  margin-left: 9rem !important;
}
.ml8 {
  margin-left: 8rem !important;
}
.ml7 {
  margin-left: 7rem !important;
}
.ml6 {
  margin-left: 6rem !important;
}
.ml5 {
  margin-left: 5rem !important;
}
.ml4 {
  margin-left: 4rem !important;
}
.ml3 {
  margin-left: 3rem !important;
}
.ml2 {
  margin-left: 2rem !important;
}
.ml1 {
  margin-left: 1rem !important;
}

.h300 {
  height: 300px !important;
}

.w100 {
  width: 100% !important;
}
.w70 {
  width: 70rem !important;
}
.w30 {
  width: 30rem !important;
}
.w25 {
  width: 25rem !important;
}
.w20 {
  width: 20rem !important;
}
.w15 {
  width: 15rem !important;
}
.w14 {
  width: 14rem !important;
}
.w13 {
  width: 13rem !important;
}
.w12 {
  width: 12rem !important;
}
.w11 {
  width: 11rem !important;
}
.w10 {
  width: 10rem !important;
}
.w9 {
  width: 9rem !important;
}
.w8 {
  width: 8rem !important;
}
.w7 {
  width: 7rem !important;
}
.w6 {
  width: 6rem !important;
}
.w5 {
  width: 5rem !important;
}
.w4 {
  width: 4rem !important;
}
.w3 {
  width: 3rem !important;
}
.w2 {
  width: 2rem !important;
}
.w1 {
  width: 1rem !important;
}

.h100 {
  height: 100% !important;
}
.h15 {
  height: 15rem !important;
}
.h14 {
  height: 14rem !important;
}
.h13 {
  height: 13rem !important;
}
.h12 {
  height: 12rem !important;
}
.h11 {
  height: 11rem !important;
}
.h10 {
  height: 10rem !important;
}
.h9 {
  height: 9rem !important;
}
.h8 {
  height: 8rem !important;
}
.h7 {
  height: 7rem !important;
}
.h6 {
  height: 6rem !important;
}
.h5 {
  height: 5rem !important;
}
.h4 {
  height: 4rem !important;
}
.h3 {
  height: 3rem !important;
}
.h2 {
  height: 2rem !important;
}
.h1 {
  height: 1rem;
}

.column {
  flex-direction: column;
}

.just-end {
  justify-content: flex-end;
}

.pad1 {
  padding: 1rem !important;
}

.pad2 {
  padding: 2rem !important;
}

.pad3 {
  padding: 3rem !important;
}

.pad4 {
  padding: 4rem !important;
}
.pad5 {
  padding: 5rem !important;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.just-center {
  justify-content: center;
}

.between {
  justify-content: space-between;
}

.gap1 {
  gap: 1rem !important;
}
.gap2 {
  gap: 2rem !important;
}
.gap3 {
  gap: 3rem !important;
}
.gap4 {
  gap: 4rem !important;
}
.gap5 {
  gap: 5rem !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
