@use "../base";


.spinner{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999999999999999999;
}

.layout-wrapper {
  min-width: 100vw;
  min-height: 100vh;
  background-color: base.$background-black;
  position: relative;
}

.dashboard-container{
  padding-left: 330px;
  padding-right: 2rem;
  position: relative;
  min-height: 100vh;
}

.page-top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;

  &__upper-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.page-wrapper{
  // height: calc(100vh - 12rem);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.form-page{
  border-radius: 20px;
  overflow: scroll;
  margin-top: 2rem;
  padding:1rem;
  padding-bottom: 2rem;
  position: relative;
  min-height: calc(100vh - 8rem);

  &__columns-wrapper{
    display: flex;
    gap: 2rem;
  }
}

.form-subtitle{
  font-weight: 700;
  color: base.$yellow;
  font-size: 12px;
  margin-bottom: 1rem;
}

.form-grid{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 10px;
  margin-bottom: 1rem;
  align-items: flex-end;
}

.two-columns{
  height: min-content;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.additional-services-grid{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.page-bottom{
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.5rem;

  h2{
    font-size: 24px;
    color:base.$yellow;
    font-weight: 700;
  }

  div{
    display: flex;
    gap: 1rem;
  }
}

.image-picker{
  width: 200px;
  height: 130px;
  background-color: base.$light-background2;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
}

.images-wrapper{
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
}

.filter-button{
  height: 45px;
  background-color: base.$light-background2;
  width: 85px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  color: white;
  gap: 5px;
  cursor: pointer;
}

.vehicles-filter{
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 1rem;
  &__buttons{
    display: flex;
    gap: 1rem;
  }

  &__selects{
    width: 30%;
    display: grid;
    grid-template-rows: 1fr 1fr ;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-end;
    gap: 10px;
  }

  &__types{
    width: 350px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    &--single-type{
      width: 100%;
      height: 70px;
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
      cursor: pointer;
      background-color: base.$table-header-background;
      z-index: 9999999999;

      p{
        color: base.$gray-text;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 13.5px;
        margin-top: 5px;
      z-index: 9999999999;

      }
    }

    .selected{
      position: relative;
      border: 1px solid base.$yellow;
    }

    .selected-box{
      position: absolute;
      background-color: base.$yellow;
      border-radius: 5px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -9px;
      right: -9px;
    }

  }
}
