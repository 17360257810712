@use "../base";



.auth {
    background-color: base.$background-black;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15rem;
    position: relative;
  
    &__inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }
  
    h1 {
      color: white;
      font-size: 22px;
      width: 100%;
      font-weight: 800;
    }

    img{
        position: absolute;
        pointer-events: none;
    }

    .top-right{
        top:-82%;
        right:-70%;
        transform: scale(.3);
    }


    .bottom{
        left: 60%;
        bottom: 0%;
        transform: translateX(-50%);
    }

    .left{
        left: -100%;
        top:50%;
        transform: translateY(-50%) scale(.2) ;
    }

    .car{
        top:50%;
        left: -80%;
        transform: translateY(-50%) scale(.2);
    }

    .logo{
        top:2rem;
        left:5rem;
        scale: 1.5;
    }
  }