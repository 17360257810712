@use "../base";

input, textarea {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 2px;
  color: white;
  font-size: 14px;
}

textarea{
  resize: none;
  padding: 1rem;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width:100%;

  &__loader{
    position: absolute;
    bottom:5px;
    right: 12%;
    height: 35px;
    width: 35px;
    z-index: 999999999999999;

    &--spinner{
      position: absolute;
      left: -165%;
      top: -165%;
      scale: .2;
    }
  }

  &__label {
    color: white;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
  }

  &__input {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #2f363d;
    background-color: base.$input-background;
    border-radius: 12px;
    padding: 0 1rem;

    &--value{
      font-weight: normal;
      color: white;
      font-size: 14px;
    }

    &--check{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }





    .placeholder{
      font-weight: 400;
      color:gray;
      font-size: 14px;
      margin: unset !important;
    }

  }
}



.select-options-container {
  position: absolute;
  top: 105%;
  left: 0;
  overflow: scroll;
  height: 175px;
  width: 100%;
  z-index: 999999;
  border-radius: 5px;
}

.select-option {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: white;
  cursor: pointer;
  background-color: base.$light-background;
  transition: 0.1s ease;
  z-index: 999999;
}

.select-option:hover {
  background-color:base.$table-header-background;
  color: white;
}


.datetime-picker {
  width: 600px;
  height: 350px;
  display: flex;
  position: absolute;
  top:0;
  left: 0;
  border-radius: 15px;
  background-color: base.$light-background;
  z-index: 999;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.date-picker {
  width: 70%;
  height: 100%;
}

.calendar-title {
  display: flex;
  padding: 10px;
  gap: 1rem;
}

.calendar-days {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.single-day {
  font-size: 25px !important;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease;
  border-radius: 5px;
  color: white;
}

.single-day:hover {
  background-color: aliceblue;
  color: base.$table-header-background;
}

.past-day {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

.time-picker {
  position: relative;
  width: 30%;
  height: 100%;
  border-left: 1px solid #6363636b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  gap: 1rem;

  p{
    color: white;
  }

}

.time-picker__select {
  display: flex;
  gap: 5px;
}

.daterange-wrapper{
  position: absolute;
  top: -150%;
  left:0;
  width: max-content;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 10px;

  .confirm{
    width: 5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 4px;
    border: none;
    outline: none;
    box-shadow: rgba(35, 57, 66, 0.21) 0px 1px 2px 0px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.calendar-confirm {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 30px;
  text-align: center;
  background-color: base.$yellow;
  cursor: pointer;
  border-radius: 10px;
  line-height: 30px;
}

.selected-day {
  background-color: aliceblue;
  color: base.$background-black !important;
}

.button {
  outline: none;
  border: none;
  cursor: pointer;
  color: #1c1c1c;
  height: 45px;
  width: 190px;
  background-color: base.$yellow;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  transition: 0.1s ease;

  &:hover {
    background-color: #f6c233;
  }
}

.cancel-button{
  background-color: base.$light-background;
  border: 1px solid white;
  color: white;

  &:hover{
  background-color: base.$background-black

  }
}

.filter-select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: base.$light-background;
  height: 45px;
  width: 165px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &__options-wrapper{
    width: 100%;
    max-height: 200px;
    position: absolute;
    top:105%;
    left: 0;
    overflow: scroll;
    border-radius: 10px;
  z-index: 99999999999999;


    div{
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: base.$table-header-background;
      cursor: pointer;
      color: white;
      transition: .1s ease;
  z-index: 99999999999999;
      text-align: center;

      &:hover{
        background-color: #525b64;
      }
    }
  }

  p{
    font-size: 12px;
    font-weight: 600;
    color: white;
  }


}


.check-text{
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 250px;
  justify-content: space-between;

  &__main-wrapper{
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  &__check{
    background-color: white;
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center; 
    cursor: pointer;
  }

  p{
    font-size: 14px;
    color:white;
  }
}