@use "_base.scss";
@use "layout/dashboard";
@use "layout/nav";
@use "layout/login";
@use "layout/topBar";
@use "components/input.scss";
@use "components/table.scss";
@use "components/modal.scss";
@use "components/pagination.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-family: base.$font-family;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
