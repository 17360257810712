@use "../base";

.form-modal{
    width: 600px;
    min-height: 350px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    background-color: base.$table-header-background;
    z-index: 99999999999;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;


    &__flex{
        display: flex;
        gap: 1rem;

        >p{
            padding-top: 2rem !important;
            color: white;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    h2{
        color:white
    }

    &__buttons{
        display: flex;
        gap: 2rem;
    }

    animation: popup .3s ease-out;
}

.mileage-modal{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2F363D54;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;

    &__row{
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;

        &--title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}


.additional-services-modal{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2F363D54;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
    

    &__content{
        width: 80%;
        height: max-content;
        padding: 2rem;
        background-color: base.$light-background2;
        border-radius: 20px;
        display: flex;
        gap:2rem;
        justify-content: space-between;
        flex-direction: column;
    }
}

@keyframes popup {
    0% {
        opacity: .1;
        top:60%
    }
    100% {
      opacity: 1;
      top:50%
    }
  }

.confirm-modal{
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    height: 300px;
    background-color: base.$light-background2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    justify-content: space-between;
    animation: popup .3s ease-out forwards;

    img{
        scale: 4;
        opacity: .2;
    }

    &__buttons{
        display: flex;
        gap: 2rem;
    }

    div{
        p{
            color: white;
            font-size: 1.3rem;
            text-align: center;
        }
    }
}